import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{ fontFamily: 'Arial', fontWeight: 'bold' }}>Junta de Accionistas de Orzatra S.A</p>
        <div>
          <div className="pdf-container" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="pdf-item" style={{ padding: '10px' }}>
              <object data="./assets/boe.pdf" type="application/pdf" width="600" height="800">
                <embed src="./assets/boe.pdf" type="application/pdf" />
              </object>
            </div>
            <div className="pdf-item" style={{ padding: '10px' }}>
              <object data="./assets/article.pdf" type="application/pdf" width="600" height="800">
                <embed src="./assets/article.pdf" type="application/pdf" />
              </object>
            </div>
          </div>
        </div>
      </header>

      <footer className="App-footer" style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f5f5f5' }}>
        <div>
          <h3>Contacto</h3>
          <p>Correo: orzatra@gmail.com</p>
          <p>Teléfono fijo: 913672005</p>
          <p>Teléfono móvil: 650183014</p>
          <p>Dirección Postal: c/Musas 18, Local Bajo-B, 28022, Madrid</p>
        </div>
        <div>
          <h3>Facturación</h3>
          <p>CIF A79332409</p>
        </div>
        <div>
          <h3>Domicilio Social</h3>
          <p>c/Musas 18, Local Bajo-B, 28022, Madrid</p>
        </div>
        <div>
          <h3>Inscripción Registro Mercantil</h3>
          <p>Tomo 4447- Folio 138, Sección 8ª, Hoja M-73687 - Ins. 10ª</p>
        </div>
        <div>
          <h3>Actividad</h3>
          <p>La Sociedad tiene entre sus actividades principales: “La promoción, gestión y
            desarrollo de todo tipo de operaciones inmobiliarias y urbanísticas, mediante
            la adquisición, compraventa, construcción, planificación, ordenación,
            urbanización y parcelación e toda clase de terrenos o inmuebles; realización
            de toda clase de obras de urbanización, construcción, rehabilitación y la
            enajenación, administración y explotación, incluso en arrendamiento, de las
            fincas, edificios, viviendas, locales, plazas de garaje, parking e inmuebles en
            general, cualquiera que sea su destino, incluso hoteles, salas de fiesta y
            restaurantes.”.
            De conformidad con el artículo 20 de la Ley 14/2013, de 27 de septiembre,
            hace constar el compareciente que la Clasificación Nacional de Actividades
            Económicas (CNAE) que le corresponde es la siguiente: 6810-Compraventa
            de bienes inmuebles por cuenta propia.</p>
        </div>
        <div>
          <h3>Provincias</h3>
          <p>Madrid</p>
        </div>
        <div>
          <h3>Servicios Inmobiliarios</h3>
          <p>Compra - Venta - Alquiler - Asesoramiento - Inversiones</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
